<template>
  <div
    class="mt-0"
  >
    <v-card-text
      class="info1--text ma-0 pa-0 mb-3"
    >
      <div>
        {{ 'Pre FREEMIUM nemeniť žiadne nastavenia' }}
      </div>
      <div>
        {{ 'Pre GOSTART a SMARTFLEXI treba správne nastaviť obdobie, počet preverení a cenu za preverenie' }}
      </div>
      <div>
        {{ 'Pre PREMIUM zvážiť všetky nastavenia' }}
      </div>
    </v-card-text>

    <app-api-call
      class=""
      :call-result="callResult"
    />

    <v-select
      v-model="selectedProductName"
      :disabled="pseudoproducts.length === 0"
      :loading="!callResult.finished"
      :items="pseudoproducts"
      :label="'Select a product'"
      :item-text="'name'"
      :item-value="selectedPseudoproduct => selectedPseudoproduct.name"
    />
    <div>
      <v-textarea
        v-model="activateProductData"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UsersActivateProduct',
  props: {
    editedUser: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      selectedProductName: null,
      activateProductData: null,
      pseudoproducts: []
    }
  },
  watch: {
    selectedProductName (newValue) {
      if (newValue !== null) {
        let activateProductDataObject = {}
        activateProductDataObject.userName = this.editedUser.email
        activateProductDataObject.productName = newValue
        activateProductDataObject.packageInvestigations = 3
        activateProductDataObject.priceOverPackageInvestigations = 500
        activateProductDataObject.monthsToActivateProductFor = 1
        activateProductDataObject.enableInvestigationApi = false
        activateProductDataObject.subtractInvestigations = true
        this.activateProductData = JSON.stringify(activateProductDataObject, null, 2)
      }
    },
    activateProductData (newValue) {
      this.callResult.error = null
      try {
        let activateProductDataObject = JSON.parse(this.activateProductData)
        this.$emit('on-setting-activate-product-data', activateProductDataObject)
      } catch (e) {
        this.callResult.error = 'Invalid JSON data'
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.$xapi.get('endpointsStripeProduct/packageProducts')
        .then(r => {
          this.pseudoproducts = r.data
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        }).finally(() => {
          this.callResult.finished = true
        })
    }
  }
}
</script>
