<template>
  <div>
    <app-api-call
      class="mb-3"
      :call-result="callResult"
    />
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :loading="loading"
      :mobile-breakpoint="0"
      class="ma-0 pa-0"
      dense
      style="white-space: nowrap !important"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="white ma-0 pa-0 mb-3 pr-3"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$i18n.t('$l.app.search')"
            single-line
            hide-details
          />
          <v-spacer />
          <v-btn
            :disabled="false"
            class="primary"
            text
            dark
            icon
            width="34"
            height="34"
            @click="dialog = true"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.couponStatisticsLength`]="{ item }">
        <div
          class="okaml_cursor_pointer"
          @click="openActivatiedInfo(item)"
        >
          {{ item.couponStatistics.length }}
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2 okaml_cursor_pointer"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          class="okaml_cursor_pointer"
          @click="openActivatiedInfo(item)"
        >
          mdi-information-variant
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialog"
      max-width="600"
    >
      <v-card>
        <v-container
          fluid
          class="ma-0 pa-3"
        >
          <v-row
            dense
          >
            <v-col
              v-for="(item, index) in headers"
              :key="index"
              cols="12"
            >
              <v-text-field
                v-if="item.type && item.type === 'text'"
                v-model="editedItem[item.value]"
                :label="item.text"
                :readonly="item.readonly"
                :disabled="item.readonly"
              />
              <v-text-field
                v-if="item.type && item.type === 'date'"
                ref="datetime"
                v-model="editedItem[item.value]"
                :label="item.text"
                type="datetime-local"
              />
              <v-textarea
                v-if="item.type && item.type === 'textarea'"
                v-model="editedItem[item.value]"
                :label="item.text"
              />
              <v-checkbox
                v-if="item.type && item.type === 'checkbox'"
                v-model="editedItem[item.value]"
                :label="item.text"
              />
              <v-select
                v-if="item.type && item.type === 'selectinv'"
                v-model="editedItem[item.value]"
                :items="[2, 3, 5, 8, 10, 20, 30, 50, 100, 150, 200, 500]"
                :label="item.text"
              />
              <v-select
                v-if="item.type && item.type === 'selectproduct'"
                v-model="editedItem[item.value]"
                :items="['GOSTART']"
                :label="item.text"
              />
            </v-col>
          </v-row>
        </v-container>

        <v-row
          v-if="$v.editedItem.merchantEmail.$dirty && !$v.editedItem.merchantEmail.required"
          class="ma-0 pa-0"
        >
          <v-card-text class="subtitle-1 d-flex justify-center text-center ma-0 pa-0 error--text">
            Merchant Email is mandatory
          </v-card-text>
        </v-row>
        <v-row
          v-if="$v.editedItem.validToField.$dirty && !$v.editedItem.validToField.required"
          class="ma-0 pa-0"
        >
          <v-card-text class="subtitle-1 d-flex justify-center text-center ma-0 pa-0 error--text">
            Valid To is mandatory
          </v-card-text>
        </v-row>
        <v-row
          v-if="$v.editedItem.role.$dirty && !$v.editedItem.role.required"
          class="ma-0 pa-0"
        >
          <v-card-text class="subtitle-1 d-flex justify-center text-center ma-0 pa-0 error--text">
            API Role is mandatory
          </v-card-text>
        </v-row>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary darken-1"
            text
            @click="close"
          >
            {{ '$l.app.cancel' | translate }}
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            :disabled="false"
            @click="save"
          >
            {{ '$l.app.save' | translate }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="activatedInfoDialog"
      max-width="600"
    >
      <CouponActivatedInfo
        :coupon="activatiedInfoCoupon"
        @close="activatedInfoDialog=false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import CouponActivatedInfo from './CouponActivatedInfo.vue'

export default {
  name: 'Coupons',
  components: {
    CouponActivatedInfo
  },
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      headers: [
        { text: 'Id', value: 'id', type: 'text', readonly: true },
        { text: 'Coupon Id', value: 'couponId', type: 'text', readonly: true },
        { text: 'Activated', value: 'couponStatisticsLength', type: 'text', readonly: true },
        { text: 'Merchant Email', value: 'merchantEmail', type: 'text' },
        { text: 'Partner ICO', value: 'partnerCompanyId', type: 'text' },
        { text: 'Max Registrations', value: 'maxRegistrations', type: 'text', align: ' d-none' },
        { text: 'Valid To', value: 'validToField', type: 'date', align: ' d-none' },
        { text: 'Valid To', value: 'validToFormatted' },
        { text: 'Investigations', value: 'inPackageInvestigations', type: 'selectinv' },
        { text: 'Product', value: 'role', type: 'selectproduct' },
        { text: 'Actions', value: 'actions', align: 'end' }
      ],
      items: [],
      search: '',
      loading: false,
      dialog: false,
      activatedInfoDialog: false,
      activatiedInfoCoupon: {},
      editedItem: {}
    }
  },
  validations: {
    editedItem: {
      merchantEmail: { required },
      validToField: { required },
      role: { required }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.loading = true
      this.callResult.finished = false
      this.callResult.error = null
      this.$xapi.get('endpointsCoupon/getAllCoupons')
        .then(r => {
          this.items = r.data
          this.items.forEach(i => {
            i.couponStatisticsLength = i.couponStatistics.length
            i.merchantEmail = JSON.parse(i.data).merchantEmail
            i.partnerCompanyId = JSON.parse(i.data).partnerCompanyId
            i.maxRegistrations = JSON.parse(i.data).maxRegistrations
            i.monthsActive = JSON.parse(i.data).monthsActive
            i.validToFormatted = this.$utils.formatDate(JSON.parse(i.data).validTo)
            if (JSON.parse(i.data).validTo) {
              let date = new Date(JSON.parse(i.data).validTo)
              date = Date.parse(date) - date.getTimezoneOffset() * 60 * 1000
              i.validToField = new Date(date).toISOString().substring(0, 16)
            }
            i.inPackageInvestigations = JSON.parse(i.data).inPackageInvestigations
            i.role = JSON.parse(i.data).role
          })
          this.items = this.items.sort((a, b) => (a.id > b.id) ? 1 : -1)
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.loading = false
          this.callResult.finished = true
        })
    },
    editItem (item) {
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    close () {
      this.$v.$reset()
      this.dialog = false
      this.editedItem = {}
    },
    save () {
      this.callResult.error = null
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.editedItem.validTo = new Date(this.editedItem.validToField).toISOString()
        this.editedItem.data = JSON.stringify(this.editedItem)
        this.$xapi.put('endpointsCoupon/updateCoupon', this.editedItem)
          .then(r => {
          })
          .catch(e => {
            this.callResult.error = this.$i18n.t('$l.error.generateOrUpdateCouponFailed')
          })
          .finally(() => {
            this.init()
            this.dialog = false
          })
      }
    },
    openActivatiedInfo (item) {
      this.activatiedInfoCoupon = Object.assign({}, item)
      this.activatedInfoDialog = true
    }
  }
}
</script>
