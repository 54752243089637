var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-api-call',{staticClass:"mx-3",attrs:{"call-result":_vm.callResult}}),_c('v-data-table',{staticClass:"ma-0 pa-0",attrs:{"headers":_vm.headers,"items":_vm.mailingLists,"loading":_vm.loading,"mobile-breakpoint":0,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white ma-0 pa-0 mb-3 pr-3"}},[_c('v-spacer'),_c('v-btn',{staticClass:"primary",attrs:{"text":"","dark":"","icon":"","width":"34","height":"34"},on:{"click":_vm.createMailingList}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-inline-flex"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1 okaml_cursor_pointer",attrs:{"small":""},on:{"click":function($event){return _vm.editMailingList(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.t('$l.app.edit'))+" ")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1 okaml_cursor_pointer",attrs:{"small":""},on:{"click":function($event){return _vm.openDeleteMailingListDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-forever ")])]}}],null,true)},[(!item.isBlocked)?_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.t('$l.app.delete'))+" ")]):_vm._e()])],1)]}}],null,true)}),_c('basic-dialog',{attrs:{"title":'WARNING!!! Are you sure you want to delete mailing list:   '+_vm.mailingList.name+'  ?',"save-button":"","save-button-label":"$l.app.confirm"},on:{"close-dialog":function($event){_vm.deleteMailingListDialog = false},"save-dialog":_vm.deleteMailingList},model:{value:(_vm.deleteMailingListDialog),callback:function ($$v) {_vm.deleteMailingListDialog=$$v},expression:"deleteMailingListDialog"}}),_c('MailingListDialog',{attrs:{"display-mailing-list-dialog":_vm.mailingListDialog,"mailing-list":_vm.mailingList,"title":"Mailing List","save-button":""},on:{"close-dialog":_vm.closeMailingListDialog},model:{value:(_vm.mailingListDialog),callback:function ($$v) {_vm.mailingListDialog=$$v},expression:"mailingListDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }