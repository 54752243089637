var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('app-api-info',{attrs:{"api-info":_vm.apiError}}),_c('v-data-table',{staticClass:"ma-0 pa-0",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.loading,"mobile-breakpoint":0,"dense":""},scopedSlots:_vm._u([{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$utils.formatDate(item.startDate.replace('[UTC]', ''))))])]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$utils.formatDate(item.endDate.replace('[UTC]', ''))))])]}},{key:"item.lastModified",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$utils.formatDate(item.lastModified.replace('[UTC]', ''))))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white ma-0 pa-0 mb-3 pr-3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$i18n.t('$l.app.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"primary",attrs:{"disabled":false,"text":"","dark":"","icon":"","width":"34","height":"34"},on:{"click":function($event){return _vm.openDialog()}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2 okaml_cursor_pointer",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$i18n.t('$l.app.edit'))+" ")])])]}}],null,true)}),_c('SystemAlertBuilder',{ref:"systenAlertBuilder",on:{"system-alert-saved":_vm.init}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }