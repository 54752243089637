<template>
  <v-container
    fluid
  >
    <div
      v-if="loading"
      class="text-center"
    >
      <v-progress-circular

        :size="40"
        :width="7"
        color="success"
        indeterminate
      />
    </div>
    <div
      v-else
    >
      <div

        v-for="(video, i) in videos"
        :key="i"
      >
        <v-row
          v-cloak
          dense
          class="justify-center"
        >
          <v-col
            xl="8"
            lg="8"
            md="8"
            sm="8"
            xs="8"
            cols="12"
            class="text-center"
          >
            <v-text-field
              v-model="video.url"
              :label="'URL '+(i+1)"
            />
          </v-col>
          <v-col
            xl="4"
            lg="4"
            md="4"
            sm="4"
            xs="4"
            cols="12"
            class="text-center d-flex flex-row"
          >
            <v-btn
              class="ma-2"
              color="secondary"
              @click="deleteItem(i)"
            >
              <v-icon
                dark
                class="mr-2"
              >
                mdi-delete
              </v-icon>
            </v-btn>
            <v-btn
              v-if="i!=0"
              class="ma-2"
              color="secondary"
              @click="moveUp(i)"
            >
              <v-icon
                dark
                class="mr-2"
              >
                mdi-arrow-up-bold
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-cloak
          dense
          class="justify-center"
        >
          <v-col
            class="text-center"
          >
            <iframe
              width="448"
              height="252"
              :style="{'max-width':videoMaxWidth}"
              :src="video.url"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </v-col>
        </v-row>
      </div>

      <app-api-info
        :api-info="apiError"
        class="pb-3 mx-1"
      />
      <v-btn
        class="ma-2"
        :loading="savingVideos"
        color="secondary"
        @click="save"
      >
        <v-icon
          dark
          class="mr-2"
        >
          mdi-content-save
        </v-icon>
        {{ $i18n.t('$l.app.save') }}
      </v-btn>
      <v-btn
        class="ma-2"
        color="secondary"
        @click="addNew"
      >
        <v-icon
          dark
          class="mr-2"
        >
          mdi-plus
        </v-icon>
        {{ $i18n.t('$l.app.add') }}
      </v-btn>
      <v-btn
        class="ma-2"
        color="secondary"
        @click="loadData"
      >
        <v-icon
          dark
          class="mr-2"
        >
          mdi-refresh
        </v-icon>
        {{ $i18n.t('$l.app.reload') }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'MultimediaManagement',
  data () {
    return {
      apiError: null,
      videoMaxWidth: '400px',
      savingVideos: null,
      loading: null,
      videos: []
    }
  },
  computed: {
    pageHeight () {
      return document.body.scrollHeight
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    onResize () {
      this.videoMaxWidth = (this.$refs.iframeParent.$el.clientWidth - 32) + 'px'
    },
    save () {
      this.savingVideos = true
      this.apiError = null
      this.$xapi.post('administration/updateMultimedia', { multimedia: this.videos })
        .then(r => {
          if (r.data) {
            this.videos = r.data
          }
        })
        .catch(e => {
          this.apiError = e.response.localizedMessage
        })
        .finally(() => {
          this.savingVideos = false
        })
    },
    deleteItem (index) {
      this.videos.splice(index, 1)
    },
    addNew () {
      if (this.videos != null) {
        this.videos.push({ url: '' })
      } else { // in case we dont have data
        this.videos = [{
          url: ''
        }]
      }
    },
    loadData () {
      this.loading = true
      this.apiError = null
      this.$xapi.get('overview/multimedia')
        .then(r => {
          if (r.data) {
            this.videos = r.data
          }
        })
        .catch(e => {
          this.apiError = e.response.localizedMessage
        })
        .finally(() => {
          this.$vuetify.goTo(this.pageHeight)
          this.loading = false
        })
    },
    moveUp (oldIndex) {
      var newIndex = oldIndex - 1
      if (newIndex >= this.videos.length) {
        var k = newIndex - this.videos.length + 1
        while (k--) {
          this.videos.push(undefined)
        }
      }
      this.videos.splice(newIndex, 0, this.videos.splice(oldIndex, 1)[0])
    }
  }
}
</script>
