<template>
  <v-container>
    <basic-dialog
      v-model="dialog"
      scrollable
      max-width="60%"
      :title="$i18n.t('$l.systemalert.createAlert')"
      :fullscreen="$vuetify.breakpoint.xs"
      persistent
      @close-dialog="close()"
    >
      <app-api-call
        class="mb-3"
        :call-result="callResult"
      />
      <v-stepper v-model="stepper">
        <v-stepper-header>
          <v-stepper-step
            :complete="stepper > 1"
            step="1"
            :editable="!loading"
          >
            {{ $i18n.t('$l.systemalert.startDate') }}
          </v-stepper-step>

          <v-divider />

          <v-stepper-step
            :complete="stepper > 2"
            step="2"
            :editable="isStartDateFilled && !loading"
          >
            {{ $i18n.t('$l.systemalert.endDate') }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card
              class="mb-12"
            >
              <v-row
                justify="center"
                align="center"
                elevation="0"
              >
                <v-col
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  xs="12"
                  cols="12"
                  class="d-flex justify-center mb-6"
                >
                  <v-date-picker
                    v-model="startDate"
                    :min="minDateStart"
                    elevation="5"
                    color="success"
                  />
                </v-col>
                <v-spacer />
                <v-col
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  xs="12"
                  cols="12"
                  class="d-flex justify-center mb-6"
                >
                  <v-time-picker
                    v-model="startDateTime"
                    format="24hr"
                    elevation="5"
                    color="success"
                  />
                </v-col>
              </v-row>
            </v-card>

            <v-btn
              text
              :disabled="true"
            >
              {{ $i18n.t('$l.app.back') }}
            </v-btn>

            <v-btn
              color="primary"
              :disabled="!isStartDateFilled"
              @click="stepper = 2"
            >
              {{ $i18n.t('$l.app.next') }}
            </v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card
              class="mb-12"
            >
              <v-row
                align="center"
                justify="center"
              >
                <v-col
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  xs="12"
                  cols="12"
                  class="d-flex justify-center mb-6"
                >
                  <v-date-picker
                    v-model="endDate"
                    :min="minDateEnd"
                    elevation="5"
                    color="success"
                  />
                </v-col>
                <v-spacer />
                <v-col
                  xl="6"
                  lg="6"
                  md="12"
                  sm="12"
                  xs="12"
                  cols="12"
                  class="d-flex justify-center mb-6"
                >
                  <v-time-picker
                    v-model="endDateTime"
                    format="24hr"
                    elevation="5"
                    color="success"
                  />
                </v-col>
              </v-row>
            </v-card>

            <v-btn
              text
              :disabled="loading"
              @click="stepper = 1"
            >
              {{ $i18n.t('$l.app.back') }}
            </v-btn>
            <v-btn
              color="primary"
              :disabled="isEndDateFilled"
              :loading="!callResult.finished"
              @click="save"
            >
              {{ $i18n.t('$l.app.save') }}
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </basic-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'SystemAlertBuilder',
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      startDateTime: null,
      endDateTime: null,
      systemAlert: {},
      startDate: null,
      endDate: null,
      dialog: false,
      stepper: 1,
      valid: false,
      loading: false,
      minDateStart: new Date().toISOString().substr(0, 10)
    }
  },
  computed: {

    minDateEnd () {
      if (this.startDate == null) {
        return new Date().toISOString().substr(0, 10)
      } else {
        return new Date(this.startDate).toISOString().substr(0, 10)
      }
    },
    isStartDateFilled () {
      if (this.startDateTime != null && this.startDate != null) {
        return true
      } else {
        return false
      }
    },
    isEndDateFilled () {
      if (this.endDateTime != null && this.endDate != null) {
        return false
      } else {
        return true
      }
    }
  },
  created () {

  },
  methods: {
    formatEditInput () {
      this.systemAlert.startDate = this.$utils.formatDateToDatePicker(new Date(this.systemAlert.startDate.replace('[UTC]', '')).toISOString())
      this.systemAlert.endDate = this.$utils.formatDateToDatePicker(new Date(this.systemAlert.endDate.replace('[UTC]', '')).toISOString())
      this.startDateTime = this.systemAlert.startDate.substring(11, 16)
      this.endDateTime = this.systemAlert.endDate.substring(11, 16)
      this.startDate = this.systemAlert.startDate.substring(0, 10)
      this.endDate = this.systemAlert.endDate.substring(0, 10)
    },
    editItem (item) {
      this.systemAlert = Object.assign({}, item)
      this.dialog = true
      this.formatEditInput()
      this.callResult.finished = true
    },
    timeConverter (milliseconds) {
      var offset = new Date().getTimezoneOffset()
      var offsetMilliseconds = offset * 60 * 1000
      var milisecondsUTC = null
      if (offsetMilliseconds < 0) {
        milisecondsUTC = milliseconds + offsetMilliseconds
      } else {
        milisecondsUTC = milliseconds - offsetMilliseconds
      }

      let date = new Date(milisecondsUTC)

      return date.toISOString()
    },
    timeToMilliseconds (time) {
      var timeParts = time.split(':')
      var hoursToMins = parseInt(timeParts[0]) * 60
      var minutes = parseInt(hoursToMins) + parseInt(timeParts[1])
      var secondsStartTime = minutes * 60
      var millisecondsStartTime = secondsStartTime * 1000

      return millisecondsStartTime
    },
    formatDate () {
      this.systemAlert.startDate = this.timeConverter(new Date(this.startDate).getTime() + this.timeToMilliseconds(this.startDateTime))
      this.systemAlert.endDate = this.timeConverter(new Date(this.endDate).getTime() + this.timeToMilliseconds(this.endDateTime))
    },
    save () {
      this.callResult.error = null
      if (this.startDateTime != null && this.endDateTime != null && this.startDate != null && this.endDate != null) {
        this.systemAlert.email = sessionStorage.getItem('loggedUser')
        this.formatDate()
        this.callResult.finished = false
        this.callResult.error = null

        this.$xapi.post('administration/createSystemAlert', this.systemAlert)
          .then(r => {
            this.startDateTime = null
            this.endDateTime = null
            this.systemAlert = {}
            this.dialog = false
            this.$emit('system-alert-saved')
          })
          .catch(e => {
            this.callResult.error = e.response.localizedMessage
          })
          .finally(() => {
            this.callResult.finished = true
          })
      }
    },
    openDialog () {
      this.dialog = true
      this.callResult.finished = true
    },
    close () {
      this.dialog = false
      this.startDateTime = null
      this.endDateTime = null
      this.systemAlert = {}
      this.stepper = 1
    }

  }
}
</script>
