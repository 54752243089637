<template>
  <v-container
    fluid
  >
    <app-api-info
      :api-info="apiError"
      class="pb-3 mx-1"
    />
    <v-row
      v-cloak
      dense
      class="justify-center"
    >
      <v-col
        xl="10"
        lg="10"
        md="10"
        sm="10"
        xs="10"
        cols="12"
        class="text-center"
      >
        <v-text-field
          v-model="newsFlash.url"
          :label="'NewsFlash URL'"
        />
      </v-col>
      <v-col
        cols="12"
        sm="2"
      >
        <v-select
          v-model="newsFlash.messageCount"
          :items="[1,2,3,4,5,6,7,8,9,10]"
          label="Visible Messages"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <NewsFlashOverview
          ref="NewsFlashOverview"
          class="mt-4"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        xl="3"
        lg="3"
        md="3"
        sm="3"
        xs="3"
        cols="12"
        class="text-center d-flex flex-row"
      >
        <v-btn
          class="ma-2"
          :loading="saving"
          color="secondary"
          @click="save"
        >
          <v-icon
            dark
            class="mr-2"
          >
            mdi-content-save
          </v-icon>
          {{ $i18n.t('$l.app.save') }}
        </v-btn>
        <v-btn
          class="ma-2"
          color="secondary"
          @click="refresh"
        >
          <v-icon
            dark
            class="mr-2"
          >
            mdi-refresh
          </v-icon>
          Refresh
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NewsFlashOverview from '../overview/NewsFlashOverview.vue'

export default {
  name: 'NewsFlash',
  components: {
    NewsFlashOverview
  },
  data () {
    return {
      apiError: null,
      saving: false,
      newsFlash: { url: null, messageCount: 6 }

    }
  },
  created () {
    this.loadData()
  },
  methods: {
    save () {
      this.saving = true
      this.apiError = null
      this.$xapi.post('administration/updateNewsFlash', this.newsFlash)
        .then(r => {
          if (r.data) {
            this.refresh()
          }
        })
        .catch(e => {
          this.apiError = e.response.localizedMessage
        })
        .finally(() => {
          this.saving = false
        })
    },
    loadData () {
      this.apiError = null
      this.$xapi.get('administration/newsFlash')
        .then(r => {
          if (r.data) {
            this.newsFlash = r.data
          }
        })
        .catch(e => {
          this.apiError = e.response.localizedMessage
        })
    },
    refresh () {
      this.loadData()
      this.$refs.NewsFlashOverview.init()
    }
  }
}
</script>
