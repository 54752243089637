<template>
  <v-container
    fluid
  >
    <app-api-info
      :api-info="apiError"
    />
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :loading="loading"
      :mobile-breakpoint="0"
      class="ma-0 pa-0"
      dense
    >
      <template v-slot:[`item.created`]="{ item }">
        <span style="white-space: nowrap">{{ $utils.formatDate(item.created.replace('[UTC]', '')) }}</span>
      </template>
      <template v-slot:top>
        <v-toolbar
          flat
          color="white ma-0 pa-0 mb-3 pr-3"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$i18n.t('$l.app.search')"
            single-line
            hide-details
          />
          <v-spacer />
          <v-btn
            :disabled="false"
            class="primary"
            text
            dark
            icon
            width="34"
            height="34"
            @click="createQuiz"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2 okaml_cursor_pointer"
              v-bind="attrs"
              @click="redirectToQuiz(item.id)"
              v-on="on"
            >
              mdi-open-in-new
            </v-icon>
          </template>
          <span>
            {{ $i18n.t('$l.quizzes.testQuiz.preview') }}
          </span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2 okaml_cursor_pointer"
              v-bind="attrs"
              @click="editItem(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>
            {{ $i18n.t('$l.app.edit') }}
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      scrollable
      width="80%"
      :fullscreen="$vuetify.breakpoint.xs"
      persistent
    >
      <v-card>
        <v-card-title>
          <p v-if="edited">
            <v-icon>
              mdi-pencil
            </v-icon>

            {{ $i18n.t('$l.quizzes.testQuiz.editQuiz') }}
          </p>
          <p v-else>
            <v-icon>
              mdi-comment-multiple-outline
            </v-icon>

            {{ $i18n.t('$l.quizzes.testQuiz.createQuiz') }}
          </p>
        </v-card-title>
        <v-divider />
        <v-card-text style="height: 80%;">
          <quiz-builder
            ref="quizBuilder"
            :quiz="quiz"
            :edited="edited"
            :panels="panels"
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <div>
            <v-btn
              color="primary darken-1"
              text
              @click="close"
            >
              {{ $i18n.t('$l.app.close') }}
            </v-btn>
            <v-btn
              color="red darken-1"
              text
              @click="resetQuiz()"
            >
              {{ $i18n.t('$l.app.reset') }}
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="save"
            >
              {{ $i18n.t('$l.app.save') }}
            </v-btn>
            <v-spacer />
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import QuizBuilder from '../quiz/QuizBuilder.vue'

export default {
  name: 'QuizManagement',
  components: {
    QuizBuilder
  },
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      DialogiError: null,
      apiError: null,
      saving: false,
      panels: [],
      headers: [
        { text: this.$t('$l.quizzes.testQuiz.quizTitle'), value: 'name', type: 'text' },
        { text: this.$t('$l.quizzes.testQuiz.questions'), value: 'data.questions.length', type: 'text' },
        { text: this.$t('$l.quizzes.testQuiz.timeLimit'), value: 'timeLimit', type: 'text' },
        { text: this.$t('$l.quizzes.testQuiz.numberOfDiplayedQuestions'), value: 'showQuestions', type: 'text' },
        { text: this.$t('$l.quizzes.testQuiz.created'), value: 'created', type: 'text', sortable: true },
        { text: this.$t('$l.quizzes.testQuiz.status'), value: 'status', type: 'text' },
        { text: this.$t('$l.app.actions'), value: 'actions', align: 'end' }
      ],
      items: [],
      search: '',
      loading: false,
      dialog: false,
      edited: false,
      quiz: {},
      quizzes: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.loading = true
      this.callResult.finished = false
      this.callResult.error = null
      this.$xapi.get('education/quizzes')
        .then(r => {
          this.items = r.data
          this.items.forEach(i => {
            i.data = JSON.parse(i.data)
          })
          this.items = this.items.sort((a, b) => (a.name > b.name) ? 1 : -1)
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.loading = false
          this.callResult.finished = true
        })
    },
    save () {
      if (this.$refs.quizBuilder.areFieldsValid()) {
        let questionIndex = 0
        Array.from(this.quiz.data.questions, q => {
          let index = 0
          q.id = questionIndex
          questionIndex++
          Array.from(q.responses, r => {
            r.id = index
            index++
          })
        })
        this.quiz.data = JSON.stringify(this.quiz.data)
        this.$xapi.post('education/saveQuiz', this.quiz)
          .catch(e => {
            this.apiError = e.response.localizedMessage
          })
          .finally(() => {
            this.dialog = false
            this.panels = []
            this.init()
          })
        this.edited = false
      } else {
      }
    },
    editItem (item) {
      this.quiz = Object.assign({}, item)
      this.setPanels()
      this.dialog = true
      this.edited = true
    },
    setPanels () {
      for (let p = 0; p < this.quiz.data.questions.length; p++) {
        this.panels[p] = { valid: true, isResponseValid: true }
      }
    },
    close () {
      this.dialog = false
      this.edited = false
      this.initQuiz()
      this.init()
      this.panels = []
    },
    createQuiz () {
      this.dialog = true
      this.initQuiz()
    },
    resetQuiz () {
      this.quiz.data.questions = []
      this.quiz.name = null
      this.quiz.timeLimit = null
      this.quiz.status = null
      this.quiz.showQuestions = null
      this.quiz.data.shortDescription = null
      this.quiz.data.longDescription = null
      this.quiz.data.educationMaterialLink = null
    },
    redirectToQuiz (id) {
      this.$router.push({ path: 'quiz/' + id, administrator: true, props: true })
    },
    initQuiz () {
      this.quiz = {
        data: {
          questions: [],
          shortDescription: '',
          longDescription: '',
          educationMaterialLink: ''
        },
        timeLimit: null,
        showQuestions: null,
        name: '',
        status: ''

      }
    }
  }
}
</script>
