<template>
  <div>
    <div
      v-if="question.type === 'MULTIPLE'"
    >
      <div
        v-for="(response, responseIndex) in question.responses"
        :key="responseIndex"
      >
        <v-row
          dense
          no-gutters
          style="height: 50px;"
        >
          <v-col
            xl="3"
            lg="3"
            md="3"
            sm="3"
            xs="3"
            cols="4"
            class="text-center"
          >
            <v-checkbox
              v-model="response.correct"
              :label="$i18n.t('$l.quizzes.testQuiz.correct')"
              :error-messages="panels[questionIndex].isResponseValid ? '' : 'required'"
              @change="atLeastOneClickedCheckboxMessage(questionIndex)"
            />
          </v-col>
          <v-col
            xl="3"
            lg="3"
            md="3"
            sm="3"
            xs="3"
            cols="4"
            class="text-center"
          >
            <v-switch
              v-model="response.fixedOrder"
              :label="$i18n.t('$l.quizzes.testQuiz.fixedOrder')"
              color="primary"
              hide-details
            />
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            xs="6"
            cols="4"
            class="text-center d-flex justify-end"
          >
            <v-btn
              class="ma-2"
              color="secondary"
              @click="deleteResponse(question.responses, responseIndex)"
            >
              <v-icon
                dark
                class="mr-1"
              >
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          dense
          no-gutters
          style="height: 50px;"
        >
          <v-col
            xl="12"
            lg="12"
            md="12"
            sm="12"
            xs="12"
            cols="12"
            class="text-center d-flex flex-row"
          >
            <v-text-field
              v-model="response.text"
              required
              :rules="responseText"
              :label="$i18n.t('$l.quizzes.testQuiz.response') + ' ' +(responseIndex+1)"
              :error-message="responseTextErrorMessage()"
              @input="$v.quiz.response.$touch()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            xl="2"
            lg="2"
            md="2"
            sm="2"
            xs="2"
            cols="12"
            class="text-center d-flex flex-row"
          >
            <p>
              {{ $i18n.t(response.text) }}
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-radio-group
      v-if="question.type === 'SINGLE'"
      v-model="singleChoiceCorrectResponse"
      @change="checkResponse(question,questionIndex)"
    >
      <div
        v-for="(response, j) in question.responses"
        :key="j"
      >
        <v-row
          dense
          no-gutters
          style="height: 50px;"
        >
          <v-col
            xl="3"
            lg="3"
            md="3"
            sm="3"
            xs="3"
            cols="4"
            class="text-center d-flex flex-row"
          >
            <v-radio
              :value="j"
              :label="$i18n.t('$l.quizzes.testQuiz.correct')"
              :error-message="panels[questionIndex].isResponseValid ? 's' : 'required'"
            />
          </v-col>
          <v-col
            xl="3"
            lg="3"
            md="3"
            sm="3"
            xs="3"
            cols="4"
            class="text-center"
          >
            <v-switch
              v-model="response.fixedOrder"
              :label="$i18n.t('$l.quizzes.testQuiz.fixedOrder')"
              color="primary"
              hide-details
            />
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            xs="6"
            cols="4"
            class="text-center d-flex justify-end"
          >
            <v-btn
              class="ma-2"
              color="secondary"
              @click="deleteResponse(question.responses, j)"
            >
              <v-icon
                dark
                class="mr-1"
              >
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          dense
          no-gutters
          style="height: 50px;"
        >
          <v-col
            xl="12"
            lg="12"
            md="12"
            sm="12"
            xs="12"
            cols="12"
            class="text-center d-flex flex-row"
          >
            <v-text-field
              v-model="response.text"
              :label="$i18n.t('$l.quizzes.testQuiz.response') + ' ' +(j+1)"
              required
              :rules="responseText"
              :error-message="responseTextErrorMessage()"
              @input="$v.quiz.response.$touch()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            xl="2"
            lg="2"
            md="2"
            sm="2"
            xs="2"
            cols="12"
            class="text-center d-flex flex-row"
          >
            <p>
              {{ $i18n.t(response.text) }}
            </p>
          </v-col>
        </v-row>
      </div>
    </v-radio-group>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'QuizResponsesComponent',
  props: {
    question: {
      type: Object,
      required: true
    },
    questionIndex: {
      type: Number,
      required: true
    },
    panels: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      singleChoiceCorrectResponse: null,
      responseText: [
        (v) => !!v || this.$i18n.t('$l.app.required')
      ]
    }
  },
  created () {
    this.checkQuestion()
  },
  validations: {
    quiz: {
      response: {
        required
      }
    }
  },
  methods: {
    checkQuestion () {
      if (this.question.type === 'SINGLE') {
        Array.from(this.question.responses, r => {
          if (r.correct) {
            this.singleChoiceCorrectResponse = r.id
          }
        })
      }
    },
    atLeastOneClickedCheckboxMessage (index) {
      this.validateResponses(index)
    },
    checkResponse (question, index) {
      this.$emit('update-choice-index', this.singleChoiceCorrectResponse)
      this.$emit('check-response', question, index)
    },
    validateResponses (index) {
      this.$emit('validate-responses', index)
    },
    deleteResponse (responses, index) {
      responses.splice(index, 1)
    },
    responseTextErrorMessage () {
      if (this.$v.quiz.response.$dirty && !this.$v.quiz.response.required) {
        return this.$i18n.t('$l.app.required')
      } else {
        return ''
      }
    }
  }
}
</script>
