<template>
  <v-expansion-panels
    v-model="active"
    class="ma-0 pa-0"
    focusable
  >
    <v-expansion-panel
      v-for="(question, i) in quiz.data.questions"
      :key="i"
      class="mt-2"
      :error-message="questionTextErrorMessage()"
    >
      <v-form
        :ref="'formPanel' + i"
        v-model="panels[i].valid"
      >
        <v-expansion-panel-header>
          <v-row
            dense
          >
            <v-col
              xl="8"
              lg="8"
              md="8"
              sm="7"
              xs="7"
              cols="7"
              align-self="center"
              class="text-center d-flex flex-row"
            >
              <div v-if="question.text">
                <p>
                  {{ $i18n.t(question.text) }}
                </p>
              </div>
              <div v-else>
                <p>
                  {{ $i18n.t('$l.quizzes.testQuiz.question') + ' ' + (i+1) }}
                </p>
              </div>
            </v-col>
            <v-col
              xl="1"
              lg="1"
              md="1"
              sm="1"
              xs="1"
              cols="1"
              class="text-center d-flex flex-row"
            >
              <v-icon
                v-show="(!panels[i].valid || !panels[i].isResponseValid)"
                large
              >
                mdi-comment-alert-outline
              </v-icon>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          eager
        >
          <v-row
            dense
            no-gutters
            style="height: 50px;"
          >
            <v-col
              xl="11"
              lg="11"
              md="11"
              sm="11"
              xs="11"
              cols="9"
              class="text-center d-flex flex-row"
            >
              <v-text-field
                v-model="question.text"
                required
                :rules="questionText"
                :label="$i18n.t('$l.quizzes.testQuiz.question') + ' ' + (i+1)"
                :error-message="questionTextErrorMessage()"
                @input="$v.quiz.question.$touch()"
              />
            </v-col>
            <v-col
              xl="1"
              lg="1"
              md="1"
              sm="1"
              xs="1"
              cols="3"
              class="text-center d-flex flex-row"
            >
              <v-btn
                class="ma-2"
                color="secondary"
                @click="addResponse(question)"
              >
                <v-icon
                  dark
                  class="mr-1"
                >
                  mdi mdi-message-text-outline
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <QuizResponsesComponent
            :question-index="i"
            :question="question"
            :panels="panels"
            @validate-responses="validateResponses"
            @check-response="checkResponse"
            @update-choice-index="updateChoiceIndex"
          />
          <v-row>
            <v-col
              class="d-flex justify-end"
            >
              <v-btn
                class="ma-0 red--text"
                text
                @click="deleteQuestion(i)"
              >
                <v-icon
                  dark
                  class="mr-2 pa-0"
                >
                  mdi-delete
                </v-icon>
                {{ $i18n.t('$l.app.remove') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-form>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import QuizResponsesComponent from './QuizResponsesComponent'
import { required } from 'vuelidate/lib/validators'

export default {
  components: {
    QuizResponsesComponent
  },
  props: {
    quiz: {
      type: Object,
      required: true
    },
    panels: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      active: null,
      singleChoiceCorrectResponse: null,
      questionText: [
        (v) => !!v || this.$i18n.t('$l.app.required')
      ]
    }
  },
  validations: {
    quiz: {
      question: {
        required
      }
    }
  },
  methods: {
    deleteQuestion (index) {
      this.quiz.data.questions.splice(index, 1)
      this.panels.splice(index, 1)
      this.active = null
    },
    questionTextErrorMessage () {
      if (this.$v.quiz.question.$dirty && !this.$v.quiz.question.required) {
        return this.$i18n.t('$l.app.required')
      } else {
        return ''
      }
    },
    addResponse (question) {
      question.responses.push({ id: null, text: '', correct: false })
    },
    checkResponse (question, index) {
      Array.from(question.responses, r => {
        r.correct = false
      })
      question.responses[this.singleChoiceCorrectResponse].correct = true

      this.validateResponses(index)
    },
    updateChoiceIndex (index) {
      this.singleChoiceCorrectResponse = index
    },
    validateResponses (index) {
      this.$emit('validate-responses', index)
    },
    validateAllQuestions () {
      let error = 0

      for (let a = 0; a < this.panels.length; a++) {
        this.$refs['formPanel' + a][0].validate() // TRIGGER FOR ALL QUESTIONS - PANEL
        this.validateResponses(a)
        if (!this.panels[a].valid) {
          error++
        }
      }

      if (error === 0) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
