<template>
  <v-card>
    <v-card-title>
      {{ coupon.couponId }}
      <v-spacer />
      {{ JSON.parse(coupon.data).merchantEmail }}
    </v-card-title>
    <v-data-table
      v-if="coupon"
      :headers="headers"
      :items="coupon.couponStatistics"
      :mobile-breakpoint="0"
      class="ma-0 pa-0 okaml_cursor_pointer"
      dense
      hide-default-footer
      style="white-space: nowrap !important"
    >
      <template v-slot:[`item.couponActivationDate`]="{ item }">
        {{ $utils.formatDateWithoutTime(item.couponActivationDate) }}
      </template>
      <template v-slot:[`item.userLastInvestigationDate`]="{ item }">
        {{ $utils.formatDateWithoutTime(item.userLastInvestigationDate) }}
      </template>
    </v-data-table>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="blue darken-1"
        text
        @click="closeDialog"
      >
        {{ '$l.app.cancel' | translate }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'CouponActivatedInfo',
  props: {
    coupon: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      headers: [
        { text: 'Login', value: 'userEmail', type: 'text', readonly: true },
        { text: 'Total Cases', value: 'userInvestigationsCount', type: 'text', readonly: true },
        { text: 'Activated', value: 'couponActivationDate', type: 'text', readonly: true },
        { text: 'Last Case Activity', value: 'userLastInvestigationDate', type: 'text', readonly: true }
      ]
    }
  },
  methods: {
    closeDialog () {
      this.$emit('close')
    }
  }
}
</script>
