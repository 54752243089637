<template>
  <div>
    <v-card>
      <app-api-call
        :call-result="callResult"
        class="mx-3"
      />
      <v-card-title class="subtitle-1">
        {{ $i18n.t('$l.api.pushCurrentVersionDescription') }}
      </v-card-title>
      <v-card-text
        class=""
      >
        <v-btn
          text
          class="primary mb-1 mr-1"
          :loading="loading"
          @click="checkVesion"
        >
          {{ $i18n.t('$l.api.checkCurrentVersion') }}
        </v-btn>
        <v-btn
          text
          class="primary mb-1 mr-1"
          :loading="loading"
          @click="pushCurrentVersion"
        >
          {{ $i18n.t('$l.api.pushCurrentVersion') }}
        </v-btn>
        <v-btn
          text
          class="warning mb-1"
          :loading="loading"
          @click="reloadDatabasesDialog = true"
        >
          {{ 'Reload All Databases' }}
        </v-btn>
      </v-card-text>
    </v-card>

    <basic-dialog
      v-model="reloadDatabasesDialog"
      title="Reload All Databases"
      save-button
      save-button-label="$l.app.confirm"
      @close-dialog="reloadDatabasesDialog = false"
      @save-dialog="reloadAllDatabases"
    />
  </div>
</template>

<script>

export default {
  name: 'DeploymentManagement',
  data () {
    return {
      callResult: { finished: true, authorized: false, error: null, info: null },
      loading: false,
      reloadDatabasesDialog: false
    }
  },
  methods: {
    pushCurrentVersion () {
      this.loading = true
      this.callResult.info = null
      this.callResult.error = null
      this.$xapi.get('administration/websocketPushFrontendVersion')
        .then(r => {
          this.callResult.info = this.$i18n.t('$l.api.pushCurrentVersionOkMsg') + ' ' + this.$utils.getCurrentDate()
        })
        .catch(e => {
          if (e.response.localizedMessage) {
            this.callResult.error = e.response.localizedMessage
          } else {
            this.callResult.error = 'Error'
          }
        }).finally(() => {
          this.loading = false
        })
    },

    checkVesion () {
      this.loading = true
      this.callResult.info = null
      this.callResult.error = null
      this.$xapi.get('administration/version')
        .then(r => {
          this.callResult.info = r.data
        })
        .catch(e => {
          if (e.response.localizedMessage) {
            this.callResult.error = e.response.localizedMessage
          } else {
            this.callResult.error = 'Error'
          }
        }).finally(() => {
          this.loading = false
        })
    },

    reloadAllDatabases () {
      this.$xapi.post('xternal/reloadAllDatabases')
    }
  }
}
</script>
