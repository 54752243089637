<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="mailingList.name"
            :rules="nameRules"
            label="Name"
            required
          />
        </v-col>

        <v-col
          cols="12"
          md="12"
        >
          <v-textarea
            v-model="mailingList.description"
            outlined
            name="input-7-4"
            :label="$i18n.t(`$l.administration.description`)"
            value=""
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="newEmail"
            :rules="emailRules"
            label="E-mail"
            required
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-btn
            tile
            color="success"
            @click="addNewEmail"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            {{ $i18n.t('$l.app.add') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-list
        subheader
        flat
      >
        <v-divider />

        <v-subheader>
          {{ $i18n.t('$l.administration.emailList') }}
        </v-subheader>

        <v-list-item-group
          multiple
        >
          <v-list-item
            v-for="(p, i) in mailingList.emails"
            :key="i"
            class="kuv_list_item"
          >
            <v-list-item-icon>
              <v-icon v-text="'mdi-account'" />
            </v-list-item-icon>
            <v-list-item-content class="ma-0 pa-0">
              <v-list-item-title class="subtitle-1 ma-0 pa-0">
                {{ p }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon
                @click="removeFromList(i)"
              >
                mdi-delete-forever
              </v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: 'MailingListForm',
  props: {
    mailingList: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      newEmail: '',
      valid: true,
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length > 5) || 'Name must be more than 5 characters'
      ],
      emailRules: [
        // v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
      ]
    }
  },
  methods: {
    createMailingList () {},
    updateMailingList () {},
    removeFromList (index) {
      this.mailingList.emails.splice(index, 1)
    },
    addNewEmail () {
      this.mailingList.emails.push(this.newEmail)
    },
    validate () {
      this.$refs.form.validate()
    }
  }
}
</script>
