<template>
  <div>
    <app-api-call
      class="ma-0 mt-4"
      :call-result="callResult"
    />
    <v-container
      fluid
      class="greydd"
    >
      <v-row
        dense
      >
        <v-col
          cols="12"
          xs="12"
          sm="8"
        >
          <v-slider
            v-model="lastDays"
            :label="getSliderLabel()"
            min="0"
            max="90"
            thumb-label
            @change="init"
          />
        </v-col>
        <v-col
          cols="6"
          xs="6"
          sm="2"
        >
          <v-select
            v-model="nextRows"
            :items="nextRowsItems"
            label="Rows"
            @change="renderCaseStatisticsChart(startIndex)"
          />
        </v-col>
        <v-col
          cols="6"
          xs="6"
          sm="2"
        >
          <v-text-field
            v-model="chartHeight"
            label="Chart Height px"
            @change="renderCaseStatisticsChart(startIndex)"
          />
        </v-col>
      </v-row>
      <v-row
        class="ma-0 text-center justify-center"
      >
        <v-col
          ref="caseStatisticsChart"
        />
      </v-row>
      <v-row
        class="ma-0 text-center justify-center"
      >
        <v-btn
          class="ma-1 primary"
          small
          text
          :loading="exportInProgress"
          @click="exportToFile"
        >
          export
        </v-btn>
        <v-btn
          class="ma-1 error"
          small
          text
          @click="getNextRows(0)"
        >
          reset
        </v-btn>
        <v-btn
          class="ma-1 info"
          small
          text
          @click="getNextRows(nextRows)"
        >
          next
        </v-btn>
        <v-btn
          :loading="loadingSendEmailReport"
          class="ma-1 primary"
          small
          text
          @click="sendEmailReports"
        >
          Send e-mail reports for last {{ lastDays }} days
        </v-btn>
        <SendAPIReport />
        <v-card-text
          v-if="userActivity"
        >
          Report data: <b>{{ 'Days: '+userActivity.days+' Users:'+userActivity.newUsers+'/'+userActivity.allUsers + ' Cases: '+ userActivity.newCases+'/'+userActivity.allCases }}</b>
          <v-btn
            class="mx-2"
            fab
            dark
            x-small
            text
            color="error"
            @click="userActivity=null"
          >
            <v-icon dark>
              mdi-close-thick
            </v-icon>
          </v-btn>
        </v-card-text>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ApexCharts from 'apexcharts'
import SendAPIReport from './SendAPIReport.vue'

export default {
  name: 'CaseStatistics',
  components: {
    SendAPIReport
  },
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      processedRequestData: {},
      startIndex: 0,
      statisticsChart: null,
      nextRows: 7,
      nextRowsItems: [7, 20, 50],
      chartHeight: 300,
      exportInProgress: false,
      lastDays: 0,
      userActivity: null,
      loadingSendEmailReport: false
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.callResult.finished = false
      this.callResult.error = null
      this.$xapi.get('administration/caseStatistics?days=' + this.lastDays)
        .then(r => {
          this.processedRequestData = this.processData(r.data)
          this.startIndex = 0
          this.renderCaseStatisticsChart(this.startIndex)
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(
          this.callResult.finished = true
        )
    },
    sendEmailReports () {
      this.callResult.error = null
      this.loadingSendEmailReport = true
      this.$xapi.post('administration/sendInternalReportEmail?days=' + this.lastDays)
        .then(r => {
          this.userActivity = r.data
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
          this.loadingSendEmailReport = false
        })
    },
    processData (requestData) {
      let userSet = new Set()
      requestData.forEach(u => {
        let contains = false
        userSet.forEach(s => {
          if (s.user === u.user) {
            contains = true
          }
        })
        if (!contains) {
          userSet.add({ user: u.user, registered: u.registered })
        }
      })
      requestData.forEach(u => {
        userSet.forEach(s => {
          if (s.user === u.user) {
            if (u.status === 'CLOSED') {
              s.closed = u.count
              s.closedlast = u.lastcase
            } else {
              s.open = u.count
              s.openlast = u.lastcase
            }
          }
        })
      })
      userSet.forEach(s => {
        s.closed = s.closed ? s.closed : 0
        s.open = s.open ? s.open : 0
        s.total = s.closed + s.open
      })
      let processedRequestData = Array.from(userSet).sort((a, b) => (a.total > b.total) ? -1 : 1)
      return processedRequestData
    },
    getNextRows (next) {
      if (next === 0) {
        this.startIndex = 0
      } else {
        if (this.startIndex + this.nextRows <= this.processedRequestData.length) {
          this.startIndex = this.startIndex + this.nextRows
        }
      }
      this.renderCaseStatisticsChart(this.startIndex)
    },
    renderCaseStatisticsChart (startIndex) {
      let totalCases = []
      let openCases = []
      let closedCases = []
      let userCategories = []
      for (let index = startIndex; (index < this.processedRequestData.length && index - startIndex < this.nextRows); index++) {
        const e = this.processedRequestData[index]
        totalCases.push(e.total)
        openCases.push(e.open)
        closedCases.push(e.closed)
        userCategories.push(e.user)
      }
      var options = {
        title: {
          text: 'Case Statistics Chart'
        },
        chart: {
          type: 'bar',
          height: this.chartHeight,
          stacked: true
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left'
        },
        series: [{
          name: 'Total Cases',
          data: totalCases
        }, {
          name: 'Open Cases',
          data: openCases
        }, {
          name: 'Closed Cases',
          data: closedCases
        }],
        xaxis: {
          categories: userCategories,
          labels: {
            show: false
          }
        }
      }
      if (this.statisticsChart === null) {
        this.statisticsChart = new ApexCharts(this.$refs.caseStatisticsChart, options)
      } else {
        this.statisticsChart.destroy()
        this.statisticsChart = new ApexCharts(this.$refs.caseStatisticsChart, options)
      }
      this.statisticsChart.render()
    },
    exportToFile () {
      this.exportInProgress = true
      let userData = ''
      this.processedRequestData.forEach(u => {
        userData += u.user + ' # '
        userData += 'deň registrácie: ' + u.registered + ' # '
        userData += 'počet preverení: ' + u.total + ' # '
        userData += 'otvorených: ' + u.open + ' # '
        userData += 'uzavretých: ' + u.closed + ' # '
        userData += 'naposledy otvorené: ' + u.openlast + '\n'
      })
      const linkSource = 'data:text/plain;charset=utf-8,' + encodeURIComponent(userData)
      const downloadLink = document.createElement('a')
      document.body.appendChild(downloadLink)
      downloadLink.href = linkSource
      downloadLink.target = '_self'
      downloadLink.download = 'okaml_user_stats.txt'
      downloadLink.click()
      this.exportInProgress = false
    },
    getSliderLabel () {
      return 'Selected last ' + this.lastDays + ' days'
    }
  }
}
</script>
