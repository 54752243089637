<template>
  <v-container
    fluid
  >
    <app-api-call
      class="ma-0 pa-0 mt-4"
      :call-result="callResult"
    />

    <CaseStatistics class="mt-4" />

    <v-expansion-panels
      v-if="callResult.finished && callResult.authorized"
      class="ma-0 pa-0 mt-4"
    >
      <v-expansion-panel
        v-for="(item, idx) in items"
        :key="idx"
      >
        <v-expansion-panel-header>{{ item.label | translate }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <component
            :is="item.component"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import CaseStatistics from './CaseStatistics'
import Users from './Users'
import Coupons from './Coupons'
import Feedbacks from './Feedbacks'
import ManualPeps from './ManualPeps'
import Multimedia from './Multimedia'
import NewsFlash from './NewsFlash'
import SystemAlertManagement from './SystemAlertManagement'
import QuizManagement from './QuizManagement'
import DeploymentManagement from './DeploymentManagement'
import MailingListsManagement from './MailingListsManagement'

export default {
  name: 'AdministrationWrapper',
  components: {
    CaseStatistics,
    Users,
    Coupons,
    Feedbacks,
    ManualPeps,
    Multimedia,
    NewsFlash,
    SystemAlertManagement,
    QuizManagement,
    DeploymentManagement,
    MailingListsManagement
  },
  data () {
    return {
      snackbar: false,
      callResult: { finished: false, authorized: false, error: null, info: null },
      items: [
        { label: '$l.administration.manageUsers', component: 'Users' },
        { label: '$l.administration.manageCoupons', component: 'Coupons' },
        { label: '$l.administration.manageFeedbacks', component: 'Feedbacks' },
        { label: '$l.administration.manualPeps', component: 'ManualPeps' },
        { label: '$l.administration.manageMultimedia', component: 'Multimedia' },
        { label: '$l.administration.manageNewsFlash', component: 'NewsFlash' },
        { label: '$l.administration.manageSystemAlert', component: 'SystemAlertManagement' },
        { label: '$l.administration.manageQuiz', component: 'QuizManagement' },
        { label: '$l.administration.manageDeployment', component: 'DeploymentManagement' },
        { label: '$l.administration.manageMailingLists', component: 'MailingListsManagement' }
      ]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.callResult.finished = false
      this.$xapi.get('endpointsUserProfile/isAdminUser')
        .then(r => {
          this.callResult.authorized = true
        })
        .catch(e => {
          this.$router.push('/overview')
        })
        .finally(() => {
          this.callResult.finished = true
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep {.v-expansion-panel-content__wrap {
    padding: 0px !important;
  }}
</style>
