<template>
  <div>
    <app-api-call
      class="mb-3"
      :call-result="callResult"
    />

    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :loading="loading"
      :mobile-breakpoint="0"
      class="ma-0 pa-0 okaml_cursor_pointer"
      dense
      style="white-space: nowrap !important"
      @click:row="editItem"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="white ma-0 pa-0 mb-3 pr-3"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$i18n.t('$l.app.search')"
            single-line
            hide-details
          />
          <v-spacer />
          <v-btn
            :disabled="true"
            class="primary"
            text
            dark
            icon
            width="34"
            height="34"
            @click="dialog = true"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-toolbar>
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialog"
      max-width="600"
    >
      <v-card>
        <v-container
          fluid
          class="ma-0 pa-3"
        >
          <v-row
            dense
          >
            <v-col
              v-for="(item, index) in headers"
              :key="index"
              cols="12"
            >
              <v-text-field
                v-if="item.type && item.type === 'text'"
                v-model="editedItem[item.value]"
                :label="item.text"
                :readonly="item.readonly"
                :disabled="item.readonly"
              />
              <v-textarea
                v-if="item.type && item.type === 'textarea'"
                v-model="editedItem[item.value]"
                :label="item.text"
              />
              <v-checkbox
                v-if="item.type && item.type === 'checkbox'"
                v-model="editedItem[item.value]"
                :label="item.text"
              />
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary darken-1"
            text
            @click="close"
          >
            {{ '$l.app.cancel' | translate }}
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            :disabled="true"
            @click="save"
          >
            {{ '$l.app.save' | translate }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'Feedbacks',
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      headers: [
        { text: 'Login', value: 'login', type: 'text' },
        { text: 'Experience', value: 'experience', type: 'text' },
        { text: 'Category', value: 'category', type: 'text' },
        { text: 'Created', value: 'created', type: 'text', align: 'end' },
        { text: 'Text', value: 'text', type: 'textarea', align: ' d-none' }
      ],
      items: [],
      search: '',
      loading: false,
      dialog: false,
      editedItem: {}
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.loading = true
      this.callResult.finished = false
      this.callResult.error = null
      this.$xapi.get('endpointsFeedback')
        .then(r => {
          this.items = r.data
          this.items.forEach(i => {
            i.created = this.$utils.formatDate(i.lastModified)
          })
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.loading = false
          this.callResult.finished = true
        })
    },
    editItem (item) {
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.editedItem = {}
    },
    save () {
    }
  }
}
</script>
