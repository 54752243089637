<template>
  <v-container
    fluid
  >
    <app-api-info
      :api-info="apiError"
    />
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :loading="loading"
      :mobile-breakpoint="0"
      class="ma-0 pa-0"
      dense
    >
      <template v-slot:[`item.startDate`]="{ item }">
        <span style="white-space: nowrap">{{ $utils.formatDate(item.startDate.replace('[UTC]', '')) }}</span>
      </template>
      <template v-slot:[`item.endDate`]="{ item }">
        <span style="white-space: nowrap">{{ $utils.formatDate(item.endDate.replace('[UTC]', '')) }}</span>
      </template>
      <template v-slot:[`item.lastModified`]="{ item }">
        <span style="white-space: nowrap">{{ $utils.formatDate(item.lastModified.replace('[UTC]', '')) }}</span>
      </template>
      <template v-slot:top>
        <v-toolbar
          flat
          color="white ma-0 pa-0 mb-3 pr-3"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$i18n.t('$l.app.search')"
            single-line
            hide-details
          />
          <v-spacer />
          <v-btn
            :disabled="false"
            class="primary"
            text
            dark
            icon
            width="34"
            height="34"
            @click="openDialog()"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2 okaml_cursor_pointer"
              v-bind="attrs"
              @click="editItem(item)"
              v-on="on"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>
            {{ $i18n.t('$l.app.edit') }}
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
    <SystemAlertBuilder
      ref="systenAlertBuilder"
      @system-alert-saved="init"
    />
  </v-container>
</template>

<script>
import SystemAlertBuilder from '../systemAlert/SystemAlertBuilder.vue'

export default {
  name: 'SystemAlertManagement',
  components: {
    SystemAlertBuilder
  },
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      DialogiError: null,
      apiError: null,
      loading: false,
      activeAlert: null,
      items: [],
      search: '',
      headers: [
        { text: this.$t('$l.systemalert.startDate'), value: 'startDate', type: 'text' },
        { text: this.$t('$l.systemalert.endDate'), value: 'endDate', type: 'text' },
        { text: this.$t('$l.systemalert.lastModified'), value: 'lastModified', type: 'text' },
        { text: 'Actions', value: 'actions', align: 'end' }

      ]
    }
  },
  created () {
    this.init()
  },
  methods: {
    editItem (item) {
      this.$refs.systenAlertBuilder.editItem(item)
    },
    openDialog () {
      this.$refs.systenAlertBuilder.openDialog()
    },
    init () {
      this.loading = true
      this.callResult.finished = false
      this.callResult.error = null
      this.$xapi.get('administration/allSystemAlerts')
        .then(alerts => {
          this.items = alerts.data
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.loading = false
          this.callResult.finished = true
        })
    }
  }
}
</script>
