<template>
  <div>
    <app-api-call
      class="ma-3 mt-0"
      :call-result="callResult"
    />
    <div
      class="justify-center text-center"
    >
      <template v-for="(item, idx) in requestData">
        <v-card-text
          :key="idx"
          class="ma-1 pa-0"
        >
          {{ item }}
        </v-card-text>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ManualPeps',
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      requestData: {}
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.callResult.finished = false
      this.$xapi.get('administration/manualPeps')
        .then(r => {
          this.requestData = r.data
        })
        .finally(
          this.callResult.finished = true
        )
    }
  }
}
</script>
