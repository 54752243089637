<template>
  <div>
    <app-api-call
      class="mx-3"
      :call-result="callResult"
    />
    <v-data-table
      :headers="headers"
      :items="mailingLists"
      :loading="loading"
      :mobile-breakpoint="0"
      class="ma-0 pa-0"
      dense
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="white ma-0 pa-0 mb-3 pr-3"
        >
          <v-spacer />
          <v-btn
            class="primary"
            text
            dark
            icon
            width="34"
            height="34"
            @click="createMailingList"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template
        v-slot:[`item.actions`]="{ item }"
      >
        <div
          class="d-inline-flex"
        >
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                class="mr-1 okaml_cursor_pointer"
                @click="editMailingList(item)"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>
              {{ $i18n.t('$l.app.edit') }}
            </span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                class="mr-1 okaml_cursor_pointer"
                v-on="on"
                @click="openDeleteMailingListDialog(item)"
              >
                mdi-delete-forever
              </v-icon>
            </template>
            <span v-if="!item.isBlocked">
              {{ $i18n.t('$l.app.delete') }}
            </span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>

    <basic-dialog
      v-model="deleteMailingListDialog"
      :title="'WARNING!!! Are you sure you want to delete mailing list:   '+mailingList.name+'  ?'"
      save-button
      save-button-label="$l.app.confirm"
      @close-dialog="deleteMailingListDialog = false"
      @save-dialog="deleteMailingList"
    />
    <MailingListDialog
      v-model="mailingListDialog"
      :display-mailing-list-dialog="mailingListDialog"
      :mailing-list="mailingList"
      title="Mailing List"
      save-button
      @close-dialog="closeMailingListDialog"
    />
  </div>
</template>

<script>
import MailingListDialog from './MailingListDialog'
export default {
  name: 'MailingListsManagement',
  components: {
    MailingListDialog
  },
  data () {
    return {
      callResult: { finished: true, authorized: false, error: null, info: null },
      loading: false,
      deleteMailingListDialog: false,
      mailingListDialog: false,
      mailingLists: [],
      mailingList: { name: '', description: '', emails: [] },
      headers: [
        { text: this.$i18n.t('$l.administration.mailingListName'), value: 'name', type: 'text' },
        { text: this.$i18n.t('$l.administration.description'), value: 'description' },
        { text: this.$i18n.t('$l.app.actions'), value: 'actions', align: 'end' }
      ],
      mailingListToBeDelited: null
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.loading = true
      this.callResult.info = null
      this.callResult.error = null
      this.$xapi.get('administration/mailingLists')
        .then(r => {
          this.mailingLists = r.data
        })
        .catch(e => {
          if (e.response.localizedMessage) {
            this.callResult.error = e.response.localizedMessage
          } else {
            this.callResult.error = 'Error'
          }
        }).finally(() => {
          this.loading = false
        })
    },
    deleteMailingList () {
      this.$xapi.post('administration/deleteMailingList', this.mailingList)
        .then(r => {
          this.mailingLists = r.data
        })
        .catch(e => {
          if (e.response.localizedMessage) {
            this.callResult.error = e.response.localizedMessage
          } else {
            this.callResult.error = 'Error'
          }
        }).finally(() => {
          this.deleteMailingListDialog = false
        })
    },
    openDeleteMailingListDialog (item) {
      this.mailingList = item
      this.deleteMailingListDialog = true
    },
    editMailingList (item) {
      this.mailingList = item
      this.mailingListDialog = true
    },
    createMailingList () {
      this.mailingList = { name: '', description: '', emails: [] }
      this.mailingListDialog = true
    },
    closeDialog () {
      this.deleteMailingListDialog = false
    },
    closeMailingListDialog () {
      this.mailingListDialog = false
      this.loadData()
    }
  }
}
</script>
