<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :loading="loading"
        class="ma-1 primary"
        small
        text
        v-bind="attrs"
        @click="sendAPIReport"
      >
        Send API reports
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        {{ dialogMessage }}
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary darken-1"
          text
          @click="dialog = false"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SendAPIReport',
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      loading: false,
      dialog: false,
      dialogMessage: null
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
    },
    sendAPIReport () {
      this.loading = true
      this.dialogMessage = false
      this.$xapi.post('administration/sendAPIReport')
        .then(r => {
          this.dialogMessage = 'API Report send to ' + r.data
        })
        .catch(e => {
          this.dialogMessage = e.response.localizedMessage
        })
        .finally(() => {
          this.dialog = true
          this.callResult.finished = true
          this.loading = false
        })
    }
  }
}
</script>
