<template>
  <basic-dialog
    v-model="displayMailingListDialog"
    title="Mailing List"
    save-button
    save-button-label="$l.app.confirm"
    :loading-save-button="loading"
    @close-dialog="closeDialog"
    @save-dialog="submit"
  >
    <app-api-call
      class="mx-3"
      :call-result="callResult"
    />
    <MailingListForm
      :mailing-list="mailingList"
    />
  </basic-dialog>
</template>

<script>
import MailingListForm from './MailingListForm'
export default {
  name: 'MailingListDialog',
  components: {
    MailingListForm
  },
  props: {
    mailingList: {
      type: Object,
      required: true
    },
    displayMailingListDialog: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      callResult: { finished: true, authorized: false, error: null, info: null },
      loading: false
    }
  },
  methods: {
    submit () {
      this.loading = true
      this.$xapi.post('administration/mailingList', this.mailingList)
        .then(r => {
          this.closeDialog()
        })
        .catch(e => {
          if (e.response.localizedMessage) {
            this.callResult.error = e.response.localizedMessage
          } else {
            this.callResult.error = 'Error'
          }
        }).finally(() => {
          this.loading = false
        })
    },
    closeDialog () {
      this.$emit('close-dialog')
    }
  }
}
</script>
