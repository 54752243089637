<template>
  <AdministrationWrapper />
</template>

<script>
import AdministrationWrapper from '@/components/administration/AdministrationWrapper'

export default {
  name: 'Administration',
  components: {
    AdministrationWrapper
  }

}
</script>
