<template>
  <v-container
    fluid
  >
    <v-form
      ref="form"
      v-model="isQuizValid"
    >
      <v-row
        justify="center"
        align="center"
      >
        <v-col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
          cols="12"
        >
          <v-text-field
            v-model="quiz.name"
            :error-messages="quizNameErrorMessage()"
            required
            :label="$i18n.t('$l.quizzes.testQuiz.quizTitle')"
            @input="$v.quiz.name.$touch()"
          />
        </v-col>
        <v-col
          xl="2"
          lg="2"
          md="2"
          sm="3"
          xs="2"
          cols="12"
        >
          <v-text-field
            v-model="quiz.timeLimit"
            :rules="[numberRule]"
            :error-messages="timeLimitErrorMessage()"
            type="number"
            required
            :label="$i18n.t('$l.quizzes.testQuiz.timeLimit')"
            @input="$v.quiz.timeLimit.$touch()"
          />
        </v-col>
        <v-col
          xl="2"
          lg="2"
          md="2"
          sm="3"
          xs="3"
          cols="12"
        >
          <v-text-field
            v-model="quiz.showQuestions"
            :rules="[numberRule]"
            :error-messages="showQuestionsErrorMessage()"
            type="number"
            required
            :label="$i18n.t('$l.quizzes.testQuiz.numberOfDiplayedQuestions')"
            @input="$v.quiz.showQuestions.$touch()"
          />
        </v-col>
        <v-col
          xl="2"
          lg="2"
          md="2"
          sm="3"
          xs="3"
          cols="12"
        >
          <v-text-field
            v-model="quiz.scoreToPass"
            :rules="[numberRule]"
            :error-messages="scoreToPassErrorMessage()"
            type="number"
            required
            :label="$i18n.t('$l.quizzes.testQuiz.scoreToPass')"
            @input="$v.quiz.showQuestions.$touch()"
          />
        </v-col>
        <v-col
          xl="2"
          lg="2"
          md="2"
          sm="3"
          xs="3"
          cols="12"
        >
          <v-select
            v-model="quiz.status"
            :items="status"
            :label="$i18n.t('$l.quizzes.testQuiz.status')"
            @input="statusCheck"
          />
        </v-col>
      </v-row>
      <p
        v-show="!validPanels"
        class="red--text"
      >
        {{ 'Quiz is not valid' }}
      </p>
      <v-row>
        <v-col
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          cols="12"
        >
          <v-text-field
            v-model="quiz.data.shortDescription"
            :label="$i18n.t('$l.quizzes.testQuiz.shortDescription')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          cols="12"
        >
          <v-textarea
            v-model="quiz.data.longDescription"
            rows="2"
            auto-grow
            :label="$i18n.t('$l.quizzes.testQuiz.longDescription')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          xl="12"
          lg="12"
          md="12"
          sm="12"
          xs="12"
          cols="12"
        >
          <v-text-field
            v-model="quiz.data.educationMaterialLink"
            :label="$i18n.t('$l.quizzes.testQuiz.educationMaterialLink')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p>
            {{ $i18n.t('$l.quizzes.testQuiz.quizQuestions') }}
          </p>
        </v-col>
      </v-row>
      <QuizQuestionsComponent
        ref="questionsComponent"
        :quiz="quiz"
        :panels="panels"
        @validate-responses="validateResponses"
      />
      <v-row
        dense
        class="mt-4"
        no-gutters
      >
        <v-col
          xl="2"
          lg="2"
          md="2"
          sm="2"
          xs="2"
          cols="12"
          class="text-center d-flex flex-row"
        >
          <v-select
            :items="types"
            :label="$i18n.t('$l.quizzes.testQuiz.addQuestion')"
            dense
            @input="addNew"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import QuizQuestionsComponent from './QuizQuestionsComponent'

import { required } from 'vuelidate/lib/validators'

export default {
  name: 'QuizBuilder',
  components: {
    QuizQuestionsComponent
  },
  props: {
    quiz: {
      type: Object,
      required: true
    },
    edited: {
      type: Boolean,
      default: false
    },
    panels: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      isQuizValid: false,
      validPanels: true,
      types: [
        {
          text: this.$i18n.t('$l.quizzes.testQuiz.singleChoice'),
          value: 'SINGLE'
        },
        {
          text: this.$i18n.t('$l.quizzes.testQuiz.multipleChoice'),
          value: 'MULTIPLE'
        }
      ],
      status: [
        {
          text: 'ACTIVE',
          value: 'ACTIVE'
        },
        {
          text: '--',
          value: ''
        }
      ],
      numberRule: v => {
        if (!isNaN(parseFloat(v)) && v > 0) {
        } else {
          return 'Number has to be over 0.'
        }
        return true
      },
      nameRule: v => {
        if (!v) {
          return this.$i18n.t('$l.app.required')
        } else {
          return true
        }
      }
    }
  },
  validations: {
    quiz: {
      name: {
        required
      },
      timeLimit: {
        required
      },
      showQuestions: {
        required
      },
      scoreToPass: {
        required
      },
      shortDescription: {
        required
      },
      longDescription: {
        required
      }
    }
  },
  mounted () {
  },
  created () {
    if (!this.edited) {
      Array.from(this.quiz.data.questions, q => {
        this.panels.push({ valid: false, isResponseValid: false })
      })
    }
  },
  methods: {
    statusCheck (status) {
      this.quiz.status = status
    },
    addNew (type) {
      if (this.quiz.data.questions != null) {
        this.quiz.data.questions.push({
          id: null,
          text: '',
          type: type,
          responses: [
            { id: null, text: '', correct: false, fixedOrder: false },
            { id: null, text: '', correct: false, fixedOrder: false },
            { id: null, text: '', correct: false, fixedOrder: false }
          ] })
        this.panels.push({ valid: false, isResponseValid: false })
      }
    },
    timeLimitErrorMessage () {
      if (this.$v.quiz.timeLimit.$dirty && !this.$v.quiz.timeLimit.required) {
        return this.$i18n.t('$l.app.required')
      } else {
        return ''
      }
    },
    quizNameErrorMessage () {
      if (this.$v.quiz.name.$dirty && !this.$v.quiz.name.required) {
        return this.$i18n.t('$l.app.required')
      } else {
        return ''
      }
    },
    showQuestionsErrorMessage () {
      if (this.$v.quiz.showQuestions.$dirty && !this.$v.quiz.showQuestions.required) {
        return this.$i18n.t('$l.app.required')
      } else {
        return ''
      }
    },
    scoreToPassErrorMessage () {
      if (this.$v.quiz.scoreToPass.$dirty && !this.$v.quiz.scoreToPass.required) {
        return this.$i18n.t('$l.app.required')
      } else {
        return ''
      }
    },
    shortDescriptionErrorMessage () {
      if (this.$v.quiz.shortDescription.$dirty && !this.$v.quiz.shortDescription.required) {
        return this.$i18n.t('$l.app.required')
      } else {
        return ''
      }
    },
    longDescriptionErrorMessage () {
      if (this.$v.quiz.longDescription.$dirty && !this.$v.quiz.longDescription.required) {
        return this.$i18n.t('$l.app.required')
      } else {
        return ''
      }
    },
    areFieldsValid () {
      this.$v.$touch()
      this.$refs.form.validate()
      let error = this.$refs.questionsComponent.validateAllQuestions()
      if (this.isQuizValid && this.atLeastClickedCheckbox() && error) {
        this.validPanels = true
        return true
      } else {
        this.validPanels = false
        return false
      }
    },
    validateResponses (index) {
      let result = null

      Array.from(this.quiz.data.questions[index].responses, r => {
        if (r.correct) {
          result++
        }
      })
      if (result > 0) {
        this.panels[index].isResponseValid = true
      } else {
        this.panels[index].isResponseValid = false
      }
    },

    atLeastClickedCheckbox () {
      let result = null
      let hasMultiple = false
      Array.from(this.quiz.data.questions, q => {
        if (q.type === 'MULTIPLE') {
          hasMultiple = true
          Array.from(q.responses, r => {
            if (r.correct) { // ASPON JEDNA ODPOVED JE ZAKLIKNUTA, VRATI TRUE
              result++
            }
          })
        }
      })
      if (result > 0) {
        return true
      } else {
        if (hasMultiple) {
          return false
        } else {
          return true
        }
      }
    }
  }
}
</script>
