<template>
  <v-tooltip left>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        small
        v-bind="attrs"
        class="mr-1 okaml_cursor_pointer"
        @click="openDialog()"
        v-on="on"
      >
        mdi-tag-text
      </v-icon>
    </template>
    <span>
      {{ $i18n.t('Upraviť kategórie') }}
    </span>

    <basic-dialog
      v-model="dialog"
      title="Upraviť kategóriu"
      :loading-save-button="!callResult.finished"
      @close-dialog="dialog = false"
      @save-dialog="saveDialog"
    >
      <v-container>
        <app-api-call
          class="mb-4"
          :call-result="callResult"
        />
        <v-row>
          <v-col
            cols="12"
            sm="10"
          >
            <v-combobox
              v-model="selected"
              :items="items"
              :label="$i18n.t('Zvoľte kategóriu')"
              dense
              outlined
              clearable
              multiple
            />
          </v-col>
          <v-col
            cols="6"
            sm="2"
          >
            <v-btn
              color="info1"
              class="ma-0 pa-0"
              text
              :loading="!callResult.finished"
              @click="saveDialog"
            >
              {{ $i18n.t('$l.app.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </basic-dialog>
  </v-tooltip>
</template>

<script>
export default {
  name: 'UpdateCategories',
  props: {
    editedUser: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      callResult: { finished: true, authorized: false, error: null, info: null },
      dialog: null,
      selected: [],
      items: [
        { text: this.$i18n.t('AZAH - Asociácia zábavy a hier'),
          value: 'AZAH'
        },
        { text: this.$i18n.t('NARKS - Národná asociácia realitných kancelárií Slovenska'),
          value: 'NARKS'
        },
        { text: this.$i18n.t('PEP-ONLY'),
          value: 'PEP-ONLY'
        }
      ]
    }
  },
  methods: {
    openDialog () {
      this.selected = []
      this.dialog = true
      var categories = JSON.parse(this.editedUser.amloeUserProfile.data).categories
      categories.forEach(category => {
        this.items.forEach(item => {
          if (item.value === category) {
            this.selected.push(item)
          }
        })
      })
    },
    closeDialog () {
      this.selected = []
      this.dialog = false
    },
    saveDialog () {
      this.editedItem = Object.assign({}, this.editedUser)
      var data = JSON.parse(this.editedItem.amloeUserProfile.data)
      data.categories = []
      this.selected.forEach(item => {
        data.categories.push(item.value)
      })

      this.callResult.finished = false
      this.editedItem.amloeUserProfile.data = JSON.stringify(data)

      this.callResult.error = null
      this.$xapi.put('endpointsUserProfile/upateSingleUserAsAdmin', this.editedItem)
        .then(r => {
          this.$emit('userProfileSaved', this.editedItem)
          this.closeDialog()
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
        })
    }
  }
}
</script>
