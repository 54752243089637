<template>
  <div>
    <app-api-call
      class="mx-3"
      :call-result="callResult"
    />
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :loading="loading"
      :mobile-breakpoint="0"
      class="ma-0 pa-0"
      dense
      @click:row="doNothing"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="white ma-0 pa-0 mb-3 pr-3"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$i18n.t('$l.app.search')"
            single-line
            hide-details
          />
          <v-spacer />
          <v-btn
            :disabled="true"
            class="primary"
            text
            dark
            icon
            width="34"
            height="34"
            @click="dialog = true"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.coupon`]="{ item }">
        <div :class="getCouponColor(item)">
          {{ item.coupon }}
        </div>
      </template>
      <template v-slot:[`item.lastLogin`]="{ item }">
        <span style="white-space: nowrap">{{ item.lastLogin?$utils.formatDate(item.lastLogin):null }}</span>
      </template>
      <template
        v-slot:[`item.actions`]="{ item }"
      >
        <div
          class="d-inline-flex"
        >
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                class="mr-1 okaml_cursor_pointer"
                @click="editItem(item)"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>
              {{ $i18n.t('$l.administration.editUserProfile') }}
            </span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                class="mr-1 okaml_cursor_pointer"
                @click="makeAdmin(item)"
                v-on="on"
              >
                mdi-toggle-switch
              </v-icon>
            </template>
            <span>
              {{ $i18n.t('$l.administration.adminAccesActivation') }}
            </span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                class="mr-1 okaml_cursor_pointer"
                v-on="on"
                @click="blockUserPopUp(item)"
              >
                mdi-cancel
              </v-icon>
            </template>
            <span v-if="!item.isBlocked">
              {{ $i18n.t('$l.app.block') }}
            </span>
            <span v-if="item.isBlocked">
              {{ $i18n.t('$l.app.unblock') }}
            </span>
          </v-tooltip>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                class="mr-1 okaml_cursor_pointer"
                @click="activateProduct(item)"
                v-on="on"
              >
                mdi-plus
              </v-icon>
            </template>
            <span>
              {{ $i18n.t('$l.administration.productActivation') }}
            </span>
          </v-tooltip>
          <UpdateCategories
            :edited-user="item"
            @userProfileSaved="updateUser"
          />
        </div>
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialog"
      max-width="600"
    >
      <v-card>
        <v-container
          fluid
          class="ma-0 pa-3"
        >
          <v-row
            dense
          >
            <v-col
              v-for="(item, index) in headers"
              :key="index"
              cols="12"
            >
              <v-text-field
                v-if="item.type && item.type === 'text'"
                v-model="editedItem[item.value]"
                :label="item.text"
                :readonly="item.readonly"
                :disabled="item.readonly"
                dense
              />
              <v-textarea
                v-if="item.type && item.type === 'textarea'"
                v-model="editedItem[item.value]"
                :label="item.text"
              />
              <v-checkbox
                v-if="item.type && item.type === 'checkbox'"
                v-model="editedItem[item.value]"
                :label="item.text"
              />
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary darken-1"
            text
            @click="close"
          >
            {{ '$l.app.cancel' | translate }}
          </v-btn>
          <v-btn
            color="primary darken-1"
            text
            :disabled="false"
            @click="save"
          >
            {{ '$l.app.save' | translate }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <basic-dialog
      v-model="activationDialog"
      title="$l.administration.confirmAdmin"
      save-button
      save-button-label="$l.app.confirm"
      @close-dialog="activationDialog = false"
      @save-dialog="doMakeAdmin"
    >
      <v-container>
        {{ editedItem.email }}
      </v-container>
    </basic-dialog>

    <basic-dialog
      v-model="blockUserDialog"
      :title="isBlockedUser ? '$l.administration.unblockUser' : '$l.administration.blockUser'"
      save-button
      save-button-label="$l.app.confirm"
      :loading-save-button="!callResult.finished"
      @close-dialog="blockUserDialog = false"
      @save-dialog="blockOrUnblockUser"
    >
      <v-container>
        {{ editedItem.email }}
      </v-container>
    </basic-dialog>

    <basic-dialog
      v-model="activateProductDialog"
      title="$l.administration.activateProduct"
      save-button
      save-button-label="$l.app.confirm"
      :loading-save-button="!callResult.finished"
      @close-dialog="activateProductDialog = false"
      @save-dialog="doActivateProduct"
    >
      <v-container>
        <UsersActivateProduct
          :key="usersActivateProductKey"
          :edited-user="editedItem"
          @on-setting-activate-product-data="setActivateProductDataObject"
        />
      </v-container>
    </basic-dialog>
  </div>
</template>

<script>
import UsersActivateProduct from './usersComponents/UsersActivateProduct.vue'
import UpdateCategories from './usersComponents/UpdateCategories.vue'
export default {
  name: 'Users',
  components: {
    UsersActivateProduct,
    UpdateCategories
  },
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      headers: [
        { text: 'Email', value: 'email', type: 'text' },
        { text: 'Last Login', value: 'lastLogin' },
        { text: 'Coupon', value: 'coupon' },
        { text: 'Active Product', value: 'activeProductName', type: 'text' },
        { text: 'Total Cases', value: 'totalCases', type: 'text' },
        { text: 'ICO', value: 'ico', type: 'text', align: ' d-none' },
        { text: 'Activated', value: 'activated', type: 'text' },
        { text: 'Admin', value: 'isAdmin', type: 'text' },
        { text: 'Blocked', value: 'isBlocked', type: 'text' },
        { text: 'Registered', value: 'registration', type: 'text', align: ' d-none' },
        { text: 'Data', value: 'data', type: 'textarea', align: ' d-none' },
        { text: 'UserProfile', value: 'userProfileData', type: 'textarea', align: ' d-none' },
        { text: 'Actions', value: 'actions', align: 'end' }
      ],
      items: [],
      search: '',
      loading: false,
      dialog: false,
      editedItem: {},
      activationDialog: false,
      blockUserDialog: false,
      isBlockedUser: false,
      loggedUser: sessionStorage.getItem('loggedUser'),
      activateProductDialog: false,
      usersActivateProductKey: 0,
      activateProductDataObject: null
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    doNothing () {
    },
    init () {
      this.loading = true
      this.callResult.finished = false
      this.callResult.error = null
      this.$xapi.get('endpointsUserProfile/allUsersStripDetails')
        .then(r => {
          this.items = r.data
          this.items.forEach(i => {
            i.email = JSON.parse(i.data).email
            if (JSON.parse(i.data).lastLogin) {
              i.lastLogin = JSON.parse(i.data).lastLogin
            }
            i.coupon = this.getCoupon(i)
            let userActivationData = JSON.parse(i.amloeUserProfile.data).activationData
            if (!userActivationData.activeProduct) {
              userActivationData.activeProduct = {}
            }
            i.activeProductName = userActivationData.activeProduct.name
            i.activated = JSON.parse(i.data).activated
            i.registration = this.$utils.formatDate(JSON.parse(i.data).registrationTS)
            i.ico = i.amloeUserProfile.businessId
            i.userProfileData = i.amloeUserProfile.data
            i.isAdmin = JSON.parse(i.userProfileData).roles.includes('ADMIN')
            i.isBlocked = JSON.parse(i.data).isBlocked
          })
          this.items = this.items.sort((a, b) => (a.email > b.email) ? 1 : -1)
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.loading = false
          this.callResult.finished = true
        })
    },
    editItem (item) {
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.editedItem = {}
    },
    save () {
      this.callResult.error = null
      this.editedItem.amloeUserProfile.data = this.editedItem.userProfileData
      this.$xapi.put('endpointsUserProfile/upateSingleUserAsAdmin', this.editedItem)
        .then(r => {
          this.init()
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.dialog = false
        })
    },
    makeAdmin (item) {
      this.editedItem = Object.assign({}, item)
      this.activationDialog = true
    },
    doMakeAdmin () {
      this.callResult.error = null
      let updUserProfileData = JSON.parse(this.editedItem.userProfileData)
      if (!updUserProfileData.roles.includes('ADMIN')) {
        updUserProfileData.roles.push('ADMIN')
        this.editedItem.userProfileData = JSON.stringify(updUserProfileData)
      }
      this.editedItem.amloeUserProfile.data = this.editedItem.userProfileData
      this.$xapi.put('endpointsUserProfile/upateSingleUserAsAdmin', this.editedItem)
        .then(r => {
          this.init()
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.editedItem = {}
          this.activationDialog = false
        })
    },
    getCouponColor (item) {
      var activationData = JSON.parse(item.amloeUserProfile.data).activationData
      if (activationData.activeCouponId) {
        return 'green--text'
      } else {
        return 'red--text'
      }
    },
    getCoupon (item) {
      var activationData = JSON.parse(item.amloeUserProfile.data).activationData
      if (activationData.activeCouponId) {
        return activationData.activeCouponId
      } else {
        var result = ''
        if (item.amloeCoupon) {
          item.amloeCoupon.forEach(c => {
            if (result.length === 0) {
              result += c.couponId
            } else {
              result += ', ' + c.couponId
            }
          })
        }

        return result
      }
    },
    blockUserPopUp (item) {
      if ((this.loggedUser === item.email) || (item.email === 'okaml@digitalsystems.eu')) {
        return
      }
      this.editedItem = Object.assign({}, item)
      this.isBlockedUser = JSON.parse(this.editedItem.data).isBlocked
      this.blockUserDialog = true
    },
    blockOrUnblockUser () {
      this.callResult.error = null
      this.callResult.finished = false
      this.$xapi.post('endpointsUserProfile/blockOrUnblockUser', this.editedItem.email)
        .then(r => {
          this.init()
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
          this.blockUserDialog = false
        })
    },
    activateProduct (item) {
      this.editedItem = Object.assign({}, item)
      this.usersActivateProductKey = Date.now()
      this.activateProductDialog = true
    },
    doActivateProduct () {
      if (this.activateProductDataObject !== null) {
        this.callResult.error = null
        this.callResult.finished = false
        this.$xapi.post('endpointsUserProfile/activateProduct', this.activateProductDataObject)
          .then(r => {
            this.init()
          })
          .catch(e => {
            this.callResult.error = e.response.localizedMessage
          })
          .finally(() => {
            this.callResult.finished = true
            this.activateProductDialog = false
          })
      }
    },
    setActivateProductDataObject (data) {
      this.activateProductDataObject = data
    },
    updateUser () {
      this.init()
    }
  }
}
</script>
